import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';

const Etiquetas = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [etiquetas, setEtiquetas] = useState([]);
  const { drops } = useContext(DropsContext);
  const { postLoading, postEtiquetas } = useContext(ProdutosContext);
  const { control, handleSubmit, watch, resetField } = useForm({
    defaultValues: { impressora_id: null, modelo_id: null },
  });

  const onCellEditCommit = (e) => {
    const editedRows = etiquetas?.map((c) => {
      if (c?.id === e?.id) {
        return { ...c, [e?.field]: e?.value };
      } else {
        return { ...c };
      }
    });
    setEtiquetas(editedRows);
  };

  const onSubmit = (values) => {
    postEtiquetas({
      cb: () => navigate(-1),
      data: {
        ...values,
        produtos: etiquetas?.map((e) => ({
          produto_id: e?.produto_id,
          quantidade: e?.quantidade,
        })),
      },
    });
  };

  useEffect(() => {
    setEtiquetas(
      (state?.itens || [])?.map((i) => ({
        id: i?.id,
        produto_id: i?.produto_id,
        descricao: i?.descricao,
        quantidade: 1,
      }))
    );
  }, []);

  return (
    <Container>
      <Header titulo="Imprimir Etiquetas Destinatário" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="impressora_id"
              control={control}
              label="Impressora"
              options={drops?.Impressoraweb}
              onValueChange={() => resetField('modelo_id')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="modelo_id"
              control={control}
              label="Modelo"
              options={drops?.EtiquetaModelo?.filter(
                (f) => f?.impressoraweb_id === watch('impressora_id')
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={etiquetas}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Produto',
                  flex: 4,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  editable: true,
                  sortable: false,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              // loading={postLoading}
            >
              Imprimir
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Etiquetas;
