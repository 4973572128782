import api from 'services/api';
import CryptoJS from 'crypto-js';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const encryptURL = (word, key = 'E-Solution') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

export const decryptURL = (word, key = 'E-Solution') => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  try {
    const a = JSON.parse(bytes);
    return a;
  } catch (error) {
    return null;
  }
};
