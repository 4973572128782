const styles = {
  card: {
    flex: 1,
  },
  progress: {
    flex: 2,
  },
  line: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mx: 1,
  },
};

export default styles;
