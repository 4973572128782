import React from 'react';
import Loader from 'components/Loader';
import Documento from './Documento';
import Itens from './Itens';
import Registros from './Registros';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Documento {...props} />;
    case 1:
      return <Itens {...props} />;
    case 2:
      return <Registros {...props} />;
    default:
      return null;
  }
};

export default Tabs;
