export const dropBoolean = [
  { value: 'SIM', label: 'SIM' },
  { value: 'NAO', label: 'NÃO' },
];

export const dropStatusOrdemFabricacao = [
  { value: 'PENDENTE', label: 'PENDENTE' },
  { value: 'CONCLUIDO', label: 'CONCLUÍDO' },
];

export const dropTipoOfab = [
  { value: 'MONTAGEM', label: 'MONTAGEM' },
  { value: 'DESMONTAGEM', label: 'DESMONTAGEM' },
];
