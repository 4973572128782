import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Input from 'components/Input';

const ItemModal = ({ item, onSubmit }) => {
  const defaultValues = {
    descricao: item?.descricao || '',
    quantidade: item?.quantidade || '',
    przent: item?.przent || null,
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" align="center">
          {item?.descricao}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Input name="descricao" control={control} label="Descrição" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask name="quantidade" control={control} label="Quantidade" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputMask
          name="przent"
          control={control}
          label="Prazo de Entrega"
          type="date"
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
};
export default ItemModal;
