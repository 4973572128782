import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: [],
  tipo: [],
  documento: '',
  dtemissao: {
    i: null,
    f: null,
  },
  przent: {
    i: null,
    f: null,
  },
  descricao: '',
  quantidade: '',
};

export const OrdensContext = createContext();

export const OrdensProvider = ({ children }) => {
  const [ordens, setOrdens] = useState({
    data: [],
    colunas: [],
    order: { field: 'przent', sort: 'asc' },
    filter,
    page: 0,
    size: 10,
  });

  const [getLoading, setGetLoading] = useState(false);

  const getOrdens = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: ordens?.filter,
        page: ordens?.page,
        size: ordens?.size,
        order: ordens?.order,
        ...payload,
      });
      setOrdens((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getMateriais = async ({ data, cb }) => {
    try {
      setGetLoading(true);
      const { data: res } = await api.post('/Producao/Ordens/Materiais', data);
      if (cb) {
        cb(res);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getApontamentos = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Producao/Ordens/Apontamentos', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  return (
    <OrdensContext.Provider
      value={{
        ordens,
        getLoading,
        getOrdens,
        getMateriais,
        getApontamentos,
      }}
    >
      {children}
    </OrdensContext.Provider>
  );
};
