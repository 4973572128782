const styles = {
  card: {
    marginTop: '1em',
  },
  lineLabel: {
    flex: 1,
  },
  lineLabelLink: {
    flex: 1,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  lineValue: {
    flex: 3,
  },
};

export default styles;
