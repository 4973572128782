import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { dropBoolean } from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import styles from '../styles';

const Item = ({
  drops,
  user,
  onSubmit,
  loading,
  disableActions,
  documentoItem = {},
}) => {
  const defaultValues = {
    id: documentoItem?.id,
    documento_id: documentoItem?.documento_id,
    produto_id: documentoItem?.produto_id || null,
    descricao: documentoItem?.descricao || '',
    sequencia: documentoItem?.sequencia || '',
    cfo_id: documentoItem?.cfo_id || null,
    cfiscal_id: documentoItem?.cfiscal_id || null,
    quantidade: documentoItem?.quantidade || '',
    unidade_medida_id: documentoItem?.unidade_medida_id || null,
    comissao_pc: documentoItem?.comissao_pc || '',
    desconto_vlr: documentoItem?.desconto_vlr || '',
    desconto1_pc: documentoItem?.desconto1_pc || '',
    desconto2_pc: documentoItem?.desconto2_pc || '',
    desconto3_pc: documentoItem?.desconto3_pc || '',
    desconto4_pc: documentoItem?.desconto4_pc || '',
    desconto5_pc: documentoItem?.desconto5_pc || '',
    frete: documentoItem?.frete || '',
    seguro: documentoItem?.seguro || '',
    despac: documentoItem?.despac || '',
    cstfin: documentoItem?.cstfin || '',
    eventuais: documentoItem?.eventuais || '',
    outros: documentoItem?.outros || '',
    peso: documentoItem?.peso || '',
    przent: documentoItem?.przent || null,
    referencia: documentoItem?.referencia || '',
    reserva: documentoItem?.reserva || null,
    ccusto_id: documentoItem?.ccusto_id || null,
    aplicacao_id: documentoItem?.aplicacao_id || null,
    planoconta_id: documentoItem?.planoconta_id || null,
    frota_id: documentoItem?.frota_id || null,
    pedcomp: documentoItem?.pedcomp || '',
    itempedcomp: documentoItem?.itempedcomp || '',
    observacao: documentoItem?.observacao || '',
    infadc: documentoItem?.infadc || '',
  };
  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  return (
    <Card title="Informações Gerais" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VirtualDrop
            name="produto_id"
            control={control}
            label="Produto"
            options={drops?.Produto}
            refresh="Produto"
          />
        </Grid>
        <Grid item xs={12}>
          <Input name="descricao" control={control} label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="sequencia" control={control} label="Sequência" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="cfo_id"
            control={control}
            label="CFOP"
            options={drops?.Cfo}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <VirtualDrop
            name="cfiscal_id"
            control={control}
            label="Classificação Fiscal"
            options={drops?.Cfiscal}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="quantidade" control={control} label="Quantidade" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="unidade_medida_id"
            control={control}
            label="Unidade de Medida"
            options={drops?.UnidadeMedida}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="comissao_pc" control={control} label="% Comissão" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto_vlr"
            control={control}
            label="Valor do Desconto"
            onBlur={() => setValue('desconto1_pc', '')}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto1_pc"
            control={control}
            label="% do Desconto 1"
            onBlur={() => setValue('desconto_vlr', '')}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto2_pc"
            control={control}
            label="% do Desconto 2"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto3_pc"
            control={control}
            label="% do Desconto 3"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto4_pc"
            control={control}
            label="% do Desconto 4"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="desconto5_pc"
            control={control}
            label="% do Desconto 5"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="frete" control={control} label="Frete" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="seguro" control={control} label="Seguro" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask
            name="despac"
            control={control}
            label="Despesas Acessórias"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="cstfin" control={control} label="Custo Financeiro" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="eventuais" control={control} label="Eventuais" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <InputMask name="outros" control={control} label="Outros" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask name="peso" control={control} label="Peso" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputMask
            name="przent"
            control={control}
            label="Prazo de Entrega"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Input name="referencia" control={control} label="Referência" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="reserva"
            control={control}
            label="Reserva"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="ccusto_id"
            control={control}
            label="Centro de Custo"
            options={drops?.Ccusto}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="aplicacao_id"
            control={control}
            label="Aplicação"
            options={drops?.Aplicacao}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="planoconta_id"
            control={control}
            label="Plano de Conta"
            options={drops?.Planoconta?.filter((p) => p?.operacional === 'SIM')}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Dropdown
            name="frota_id"
            control={control}
            label="Frota"
            options={drops?.Frota}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input name="pedcomp" control={control} label="Pedido de Compra" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="itempedcomp"
            control={control}
            label="Item do Pedido de Compra"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="observacao"
            control={control}
            label="Observação"
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="infadc"
            control={control}
            label="Informações Adicionais"
            multiline
            rows={5}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={handleSubmit((data) => onSubmit({ Itens: [data] }))}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Item;
