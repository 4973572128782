import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Ordens from 'views/Ordens';
import OrdensGerar from 'views/Ordens/Gerar';
import OrdensProdução from 'views/Ordens/Produção';
import OrdensEtiquetas from 'views/Ordens/Etiquetas';
import OrdensMateriais from 'views/Ordens/Materiais';
import OrdensApontamento from 'views/Ordens/Apontamento';
import OrdensApontamentos from 'views/Ordens/Apontamentos';
import Documento from 'views/Documento';
import DocumentoItem from 'views/Documento/Item';
import DocumentoAprovações from 'views/Documento/Aprovações';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = () => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Ordens" element={<Outlet />}>
      <Route path="" element={<Ordens />} />
      <Route path="Gerar" element={<OrdensGerar />} />
      <Route path="Etiquetas" element={<OrdensEtiquetas />} />
      <Route path="Producao/:hash" element={<OrdensProdução />} />
      <Route path="Materiais/:hash" element={<OrdensMateriais />} />
      <Route path="Apontamentos" element={<OrdensApontamentos />} />
      <Route path="Apontamento/:hash" element={<OrdensApontamento />} />
    </Route>
    <Route path="Documento" element={<OutletDocumento />}>
      <Route path=":id" element={<Documento />} />
      <Route path="Item" element={<DocumentoItem />} />
      <Route path="Aprovacoes" element={<DocumentoAprovações />} />
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
  </Routes>
);

export default RoutesTree;
