import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  Description,
  Extension,
  PrecisionManufacturing,
} from '@mui/icons-material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useModal, useDialog } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import CustomTabs from './Tabs';

const Item = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [tab, setTab] = useState(0);
  const { openModal, closeModal } = useModal();
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { deleteGrid } = useContext(GridContext);
  const {
    documentoItem,
    getLoading,
    getDocumentoItem,
    postLoading,
    postDocumento,
    postDocumentoItemEstrutura,
    postDocumentoItemProcesso,
  } = useContext(DocumentosContext);

  const loadData = () => {
    getDocumentoItem({ id: state?.item?.id });
    closeDialog();
    closeModal();
  };

  useEffect(() => {
    if (state?.item) {
      loadData();
    } else {
      navigate(-1);
    }
  }, [state]);

  const onSubmit = (data) => postDocumento({ data, cb: loadData });

  const onDelete = (params) =>
    openModal(
      <DeleteModal onSubmit={() => deleteGrid({ params, cb: loadData })} />
    );

  const onSubmitEstrutura = (data) =>
    postDocumentoItemEstrutura({ data, loadData });

  const onSubmitProcesso = (data) =>
    postDocumentoItemProcesso({ data, cb: loadData });

  const tabs = [
    {
      value: 0,
      key: 'Item',
      label: 'Item',
      icon: <Description />,
    },
    {
      value: 1,
      key: 'Estrutura',
      label: 'Estrutura',
      icon: <Extension />,
    },
    {
      value: 2,
      key: 'Processos',
      label: 'Processos',
      icon: <PrecisionManufacturing />,
    },
  ];

  const options = [];

  return (
    <Container>
      <Header
        titulo={`Item: ${documentoItem?.descricao || ''}`}
        outros={options}
      />
      <Card>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, v) => setTab(v)}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          {tabs.map(({ show = true, ...t }) => show && <Tab {...t} />)}
        </Tabs>
      </Card>
      <CustomTabs
        tab={tab}
        drops={drops}
        documentoItem={documentoItem}
        getLoading={getLoading}
        disableActions={state?.disableActions}
        loading={postLoading}
        onDelete={onDelete}
        onSubmit={onSubmit}
        onSubmitEstrutura={onSubmitEstrutura}
        onSubmitProcesso={onSubmitProcesso}
      />
    </Container>
  );
};

export default Item;
