import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { checkInfosProduto } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import Blank from 'assets/blank.png';
import ItemModal from './Modals/ItemModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useDialog();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumento } = useContext(DocumentosContext);
  const defaultValues = {
    natureza_operacao_id: 17,
    filial_id: user?.filial_id,
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    observacao: '',
    //ITENS
    produto_id: null,
    quantidade: '',
    descricao: '',
    przent: null,
    itens: [],
  };

  const [loading, setLoading] = useState(false);
  const [additionals, setAdditionals] = useState(null);
  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const { produto_id } = getValues();
    if (Boolean(produto_id)) {
      getAdditionals();
    } else {
      setValue('quantidade', '');
      setValue('descricao', '');
      setValue('przent', null);
      setAdditionals(null);
    }
  }, [watch('produto_id')]);

  const getAdditionals = async () => {
    const { filial_id, produto_id, quantidade } = getValues();
    setLoading(true);
    const produto = await checkInfosProduto({
      filial_id,
      produto_id,
      disponivel: 'SIM',
    });
    setAdditionals(produto);
    if (!Boolean(quantidade)) setValue('quantidade', produto?.volume || 1);
    setValue('descricao', produto?.descricao || '');
    setValue('przent', produto?.przent || null);
    setLoading(false);
  };

  const onAddItem = (values) => {
    if (!Boolean(values?.produto_id)) {
      toastWarning('Nenhum produto selecionado');
    } else if (Boolean(values?.itens?.length)) {
      toastWarning('É permitido somente um item por ordem de fabricação');
    } else {
      if (values?.itens.some((s) => s?.produto_id === values?.produto_id)) {
        toastWarning('Produto já inserido');
      } else {
        setValue('itens', [
          ...values?.itens,
          {
            produto_id: values?.produto_id,
            descricao: values?.descricao,
            quantidade: values?.quantidade,
            przent: values?.przent,
          },
        ]);
        setValue('produto_id', null);
      }
    }
  };

  const onEditItem = ({ values, index }) => {
    setValue(
      'itens',
      watch('itens')?.map((itm, i) =>
        index === i ? { ...itm, ...values } : itm
      )
    );
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 17,
        natureza_operacao_id: values?.natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.filial_id,
        documento: values?.documento,
        dtemissao: values?.dtemissao,
      },
      Itens: values?.itens?.map((item, index) => ({
        sequencia: index + 1,
        produto_id: item?.produto_id,
        descricao: item?.descricao,
        quantidade: item?.quantidade,
        przent: item?.przent,
      })),
    };
    postDocumento({ data, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header titulo="Gerar Ordem de Fabricação" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              color="primary"
              size="small"
              checked={watch('natureza_operacao_id') === 17}
              onChange={() => setValue('natureza_operacao_id', 17)}
            />
            <InputLabel>Montagem</InputLabel>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Radio
              color="primary"
              size="small"
              checked={watch('natureza_operacao_id') === 28}
              onChange={() => setValue('natureza_operacao_id', 28)}
            />
            <InputLabel>Desmontagem</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    refresh="Produto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    name="przent"
                    control={control}
                    label="Prazo de Entrega"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '-'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Prazo de Entrega</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item.przent &&
                                moment(item?.przent).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Gerar;
