import React, { useContext, useEffect } from 'react';
import { Grid, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { decryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import styles from './styles';
import moment from 'moment';

const Produção = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const decrypt = decryptURL(hash);
  const { user } = useContext(AuthContext);
  const defaultValues = {
    filial_id: user?.filial_id,
    celocal_id: null,
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    observacao: '',
    //ITENS
    produto_id: null,
    quantidade: '',
    descricao: null,
    itens: [],
  };
  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues,
  });
  const { drops } = useContext(DropsContext);
  const { postLoading, postDocumentos } = useContext(DocumentosContext);
  const { getMateriais, getLoading } = useContext(OrdensContext);

  useEffect(() => {
    getMateriais({
      data: {
        itens: decrypt?.itens?.map((m) => m?.id),
        type: decrypt?.type,
      },
      cb: (v) =>
        setValue(
          'itens',
          v?.map((v) => ({ ...v, quantidade_max: v?.quantidade }))
        ),
    });
  }, []);

  const onCellEditCommit = (e) => {
    const { itens } = getValues();
    const editedRows = itens?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade' && e?.value > c?.quantidade_max) {
          return { ...c, [e?.field]: c?.quantidade_max };
        } else {
          return { ...c, [e?.field]: e?.value };
        }
      } else {
        return { ...c };
      }
    });
    setValue('itens', editedRows);
  };

  const onSubmit = (values) => {
    const docsConcluir = {};
    const data = [
      {
        Documento: {
          especie_id: 14,
          natureza_operacao_id: 17,
          users_id: user?.id,
          filial_id: values?.filial_id,
          cadastro_id: values?.filial_id,
          celocal_id: values?.celocal_id,
          documento: values?.documento,
          dtemissao: values?.dtemissao,
        },
        Entrada: {
          dtentrada: values?.dtemissao,
          custo: decrypt?.type === 'desmontagem' ? 'SIM' : 'NAO',
        },
        Itens: values?.itens
          ?.filter((f) => Boolean(f?.quantidade))
          ?.map((item, index) => ({
            sequencia: index + 1,
            produto_id: item?.produto_id,
            descricao: item?.descricao,
            quantidade: item?.quantidade,
            preco: item?.preco,
            dhsaldo: values?.dtemissao,
            Origens: [{ documento_item_org_id: item?.documento_item_id }],
          })),
      },
    ];

    values?.itens?.map((m) => {
      docsConcluir[m?.documento_id] =
        (docsConcluir[m?.documento_id] || 0) +
        (m?.quantidade_max - m?.quantidade);
    });
    Object?.keys(docsConcluir)?.map((key) => {
      if (!Boolean(docsConcluir[key])) {
        data?.push({
          Registros: [{ documento_id: Number(key), status_id: 6 }],
        });
      }
    });

    postDocumentos({ data, cb: () => navigate(-1) });
  };

  const onDeleteItem = (row) =>
    setValue(
      'itens',
      watch('itens')?.map((p) =>
        p?.id === row?.id ? { ...p, quantidade: 0 } : p
      )
    );

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Registrar Produção" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) || f?.filial_id === watch('filial_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data do Registro"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <DataGrid
          rows={watch('itens')?.filter((f) => Boolean(f?.quantidade))}
          columns={[
            {
              field: 'descricao',
              headerName: 'Produto',
              flex: 4,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              flex: 1,
              editable: true,
              sortable: false,
            },
            {
              field: 'actions',
              headerName: 'Ações',
              type: 'actions',
              flex: 1,
              getActions: ({ row }) => [
                <GridActionsCellItem
                  icon={<Icon>delete</Icon>}
                  label="Excluir"
                  onClick={() => onDeleteItem(row)}
                />,
              ],
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          onCellEditCommit={onCellEditCommit}
        />
      </Card>
      <Grid item xs={12} textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Grid>
    </Container>
  );
};

export default Produção;
