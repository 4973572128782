import { useContext } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useModal } from 'components/Modals';
import { useForm } from 'react-hook-form';
import { GridContext } from 'contexts/GridContext';
import Button from 'components/Button';
import InputMask from 'components/InputMask';

const EditModal = ({ item, onSubmit }) => {
  const defaultValues = { ...item };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(GridContext);

  return (
    <>
      <DialogTitle>Editar Apontamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <InputMask name="quantidade" control={control} label="Quantidade" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask name="perda" control={control} label="Perda" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="inicio"
              control={control}
              label="Início"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="fim"
              control={control}
              label="Fim"
              type="datetime"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Confirmar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Voltar
        </Button>
      </DialogActions>
    </>
  );
};

export default EditModal;
