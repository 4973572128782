import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import { OrdensProvider } from 'contexts/OrdensContext';
import { DocumentosProvider } from 'contexts/DocumentosContext';
import { ProdutosProvider } from 'contexts/ProdutosContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <DocumentosProvider>
              <ProdutosProvider>
                <OrdensProvider>
                  <ModalProvider>
                    <DialogProvider>
                      <DrawerProvider>{children}</DrawerProvider>
                    </DialogProvider>
                  </ModalProvider>
                </OrdensProvider>
              </ProdutosProvider>
            </DocumentosProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
