import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { MoreVert, PlayArrow, Settings, Stop } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toastWarning } from 'utils/toast';
import { summarizer, findOnArray } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { OrdensContext } from 'contexts/OrdensContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import PauseModal from './Modals/PauseModal';
import EditModal from './Modals/EditModal';
import styles from './styles';
import moment from 'moment';

const Apontamentos = () => {
  const navigate = useNavigate();
  const rotina = 'DocumentoItemProcessoApont';
  const { openModal, closeModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { getLoading, getApontamentos } = useContext(OrdensContext);
  const { deleteGrid, deleteLoading, postGrid, postLoading } =
    useContext(GridContext);
  const [processos, setProcessos] = useState([]);
  const [selected, setSelected] = useState(null);
  const [menu, setMenu] = useState(null);
  const defaultValues = {
    ccusto_id: [drops?.Ccusto?.[0]?.value],
    executante_id: user?.id,
  };
  const { watch, control, getValues } = useForm({ defaultValues });
  const processo = processos?.find((p) => p?.id === selected);
  const apontamentos = processo?.DocumentoItemProcessoAponts || [];
  const iniciado = apontamentos?.find(
    (f) =>
      Boolean(f?.executante_id === watch('executante_id')) &&
      Boolean(f?.inicio) &&
      !Boolean(f?.fim)
  );
  const tempos = apontamentos?.map((a) => {
    return {
      executante_id: a?.executante_id,
      tempo_total:
        Boolean(a?.inicio) && Boolean(a?.fim)
          ? moment
              .duration(moment(a?.fim).diff(moment(a?.inicio)))
              .asMilliseconds()
          : 0,
    };
  });

  const getTotal = (arr) => {
    const total = summarizer(arr, 'tempo_total');
    return (
      moment.duration(total).days() +
      'd: ' +
      moment.duration(total).hours() +
      'h: ' +
      moment.duration(total).minutes() +
      'm: ' +
      moment.duration(total).seconds() +
      's '
    );
  };

  const totais = [
    {
      value: getTotal(tempos),
      label: 'Horas apontadas',
    },
    {
      value: getTotal(
        tempos?.filter((f) => f?.executante_id === watch('executante_id'))
      ),
      label:
        'Horas de ' +
        (findOnArray(watch('executante_id'), drops?.Users, 'label') || ''),
    },
    {
      value:
        (summarizer(apontamentos, 'quantidade') || 0) +
        ` ${processo?.um || ''}`,
      label: 'Total produzido',
    },
    {
      value:
        (summarizer(apontamentos, 'perda') || 0) + ` ${processo?.um || ''}`,
      label: 'Total perdido',
    },
  ];

  const menus = [
    {
      label: 'Detalhes',
      icon: 'visibility',
      action: () => navigate(`/app/Documento/${menu?.processo?.documento_id}`),
    },
    {
      label: 'Imprimir Etiqueta',
      icon: 'print',
      action: () =>
        getURLRelatorio({
          data: { codigo: 'PROD000001', item: menu?.processo?.documento_id },
        }),
    },
    {
      label: 'Liberar Produção',
      icon: 'local_shipping',
      action: () => {
        const apts = menu?.processo?.DocumentoItemProcessoAponts || [];
        const quantidade = summarizer(apts, 'quantidade') || 0;
        const data = { ...menu?.processo, quantidade };
        const rotina = 'DocumentoItemProcesso';
        postGrid({ data: { data, rotina }, cb: loadData });
      },
    },
  ];

  useEffect(() => {
    loadData();
  }, [watch('ccusto_id')]);

  const loadData = () => {
    const params = getValues();
    closeModal();
    getApontamentos({ params, cb: setProcessos });
  };

  const postData = (data) => postGrid({ data: { data, rotina }, cb: loadData });

  const handleApontamento = () => {
    const data = {
      documento_item_processo_id: selected,
      executante_id: watch('executante_id'),
      inicio: iniciado?.inicio || moment().format('YYYY-MM-DD HH:mm:ss'),
      users_id: user?.id,
    };
    if (Boolean(iniciado)) {
      const onSubmit = (values) => {
        data['id'] = iniciado?.id;
        data['quantidade'] = values?.quantidade || 0;
        data['perda'] = values?.perda || 0;
        data['fim'] = moment().format('YYYY-MM-DD HH:mm:ss');
        const itemProcessos = processos?.filter(
          (f) => f?.documento_item_id === processo?.documento_item_id
        );
        const index = itemProcessos?.findIndex((i) => i?.id === processo?.id);
        const qtd_anterior = itemProcessos[index - 1]?.quantidade || 0;
        if (index > 0 && qtd_anterior < data?.quantidade + data?.perda) {
          return toastWarning(
            `Não é possível apontar mais que ${qtd_anterior} ${
              processo?.um || ''
            }`
          );
        } else {
          return postData(data);
        }
      };
      openModal(<PauseModal onSubmit={onSubmit} />);
    } else {
      postData(data);
    }
  };

  const validateApontamento = () => {
    if (!Boolean(iniciado)) {
      const itemProcessos = processos?.filter(
        (f) => f?.documento_item_id === processo?.documento_item_id
      );
      const index = itemProcessos?.findIndex((i) => i?.id === processo?.id);
      if (index < 0) {
        return true;
      }
      if (index === 0) {
        return false;
      }
      if (index > 0) {
        const qtd_anterior = itemProcessos[index - 1]?.quantidade || 0;
        return !Boolean(qtd_anterior);
      }
    }
    return false;
  };

  const onEdit = (item) => {
    openModal(<EditModal item={item} onSubmit={postData} />);
  };

  const onDelete = (id) => {
    const params = { rotina, id };
    openModal(
      <DeleteModal onSubmit={() => deleteGrid({ params, cb: loadData })} />
    );
  };

  const TempoCell = ({ row }) => {
    const duration = moment.duration(
      moment(row?.fim).diff(moment(row?.inicio))
    );
    const lines = [
      {
        label: 'Início',
        value:
          moment(row?.inicio).isValid() &&
          moment(row?.inicio).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Fim',
        value:
          moment(row?.fim).isValid() &&
          moment(row?.fim).format('DD/MM/YYYY [às] HH:mm'),
      },
      {
        label: 'Total',
        value:
          Boolean(row?.inicio) &&
          Boolean(row?.fim) &&
          `${duration?.days()}d: ${duration?.hours()}h: ${duration?.minutes()}m: ${duration?.seconds()}s`,
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  const QuantidadeCell = ({ row }) => {
    const lines = [
      {
        label: 'Produzida',
        value: (row?.quantidade || 0) + ` ${processo?.um || ''}`,
      },
      {
        label: 'Perda',
        value: (row?.perda || 0) + ` ${processo?.um || ''}`,
      },
    ];

    return (
      <Box flex={1}>
        {lines?.map(
          ({ label, value }, i) =>
            Boolean(value) && (
              <Box key={i?.toString()} display="flex">
                <Typography flex={1} variant="caption" fontWeight="bold">
                  {label}:{' '}
                </Typography>
                <Typography flex={2} variant="caption">
                  {value}
                </Typography>
              </Box>
            )
        )}
      </Box>
    );
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Apontamento de Horas" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Card title="Processos" style={styles?.card}>
            <Dropdown
              name="ccusto_id"
              control={control}
              label="Filtrar por Centro de Custo"
              options={drops?.Ccusto}
              multiple
            />
            <Box display="flex">
              {Boolean(processos?.length) ? (
                <List style={styles?.card}>
                  {processos?.map((p, i) => {
                    const apontamentos = p?.DocumentoItemProcessoAponts;
                    const qtd = summarizer(apontamentos, 'quantidade') || 0;
                    const perda = summarizer(apontamentos, 'perda') || 0;
                    const total = p.quantidade_max || 1;
                    const progress = ((qtd + perda) / total) * 100;
                    const lines = [
                      { label: 'Nº do Documento', value: p?.documento },
                      { label: 'Operação', value: p?.operacao },
                      { label: 'Referência', value: p?.referencia },
                      {
                        label: 'Quantidade',
                        value: (p?.quantidade_max || 0) + ' ' + (p?.um || ''),
                      },
                    ];

                    const Text = () => (
                      <Box>
                        <Typography variant="subtitle2">{p?.item}</Typography>
                        {lines?.map(({ label, value }, i) => (
                          <Box key={`${i}`} display="flex">
                            <Typography variant="caption" flex={2}>
                              {label}:
                            </Typography>
                            <Typography variant="caption" flex={3}>
                              {value || '-'}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    );

                    return (
                      <ListItem
                        key={`${p?.id}`}
                        divider={i + 1 < processos?.length}
                        disablePadding
                        secondaryAction={
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={({ currentTarget }) =>
                              setMenu({ processo: p, anchorEl: currentTarget })
                            }
                          >
                            <MoreVert fontSize="small" />
                          </IconButton>
                        }
                      >
                        <ListItemButton
                          selected={selected === p?.id}
                          onClick={() => setSelected(p?.id)}
                        >
                          <ListItemIcon>
                            <Settings />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography fontWeight="bold">
                                {p?.descricao}
                              </Typography>
                            }
                            secondary={<Text />}
                            sx={styles?.progress}
                          />
                          <Box display="flex" flex={1}>
                            <Box sx={styles?.line}>
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={styles?.progress}
                                color="info"
                              />
                              <Typography
                                variant="body2"
                                flex={1}
                                textAlign="end"
                              >
                                {progress}%
                              </Typography>
                            </Box>
                          </Box>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Typography variant="caption" textAlign="center" flex={1}>
                  Nenhum processo encontrado
                </Typography>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8} display="flex">
          <Card title="Apontamentos" style={styles?.card}>
            <Grid container spacing={2}>
              {!Boolean(selected) && (
                <Grid item xs={12} textAlign="center">
                  <Typography variant="caption">
                    Nenhum processo selecionado
                  </Typography>
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12} display="flex" alignItems="center">
                  <Box flex={1} mr={2}>
                    <Dropdown
                      name="executante_id"
                      control={control}
                      label="Executante"
                      options={drops?.Users}
                    />
                  </Box>
                  <Button
                    variant={Boolean(iniciado) ? 'outlined' : 'contained'}
                    startIcon={Boolean(iniciado) ? <Stop /> : <PlayArrow />}
                    onClick={handleApontamento}
                    disabled={validateApontamento()}
                  >
                    Apontamento
                  </Button>
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12}>
                  <DataGrid
                    rows={apontamentos}
                    columns={[
                      {
                        field: 'executante',
                        headerName: 'Executante',
                        flex: 2,
                        sortable: false,
                      },
                      {
                        field: 'tempo',
                        headerName: 'Tempo',
                        flex: 1,
                        sortable: false,
                        renderCell: (props) => <TempoCell {...props} />,
                      },
                      {
                        field: 'quantidade',
                        headerName: 'Quantidade',
                        flex: 1,
                        sortable: false,
                        renderCell: (props) => <QuantidadeCell {...props} />,
                      },
                      {
                        field: 'actions',
                        headerName: 'Ações',
                        type: 'actions',
                        width: 100,
                        getActions: ({ id, row }) =>
                          row?.executante_id !== watch('executante_id')
                            ? []
                            : [
                                <GridActionsCellItem
                                  icon={<Icon>edit</Icon>}
                                  label="Editar"
                                  onClick={() => onEdit(row)}
                                />,
                                <GridActionsCellItem
                                  icon={<Icon>delete</Icon>}
                                  label="Excluir"
                                  onClick={() => onDelete(id)}
                                />,
                              ],
                      },
                    ]}
                    loading={deleteLoading || getLoading || postLoading}
                    hideFooter
                    autoHeight
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    showCellRightBorder
                    showColumnRightBorder
                    localeText={localeText}
                    getRowHeight={() => 'auto'}
                  />
                </Grid>
              )}
              {Boolean(selected) && (
                <Grid item xs={12}>
                  {totais?.map(({ label, value }) => (
                    <Box key={label} display="flex" flex={1}>
                      <Typography variant="body2" fontWeight="bold" flex={1}>
                        {label}:
                      </Typography>
                      <Typography variant="body2" flex={2}>
                        {value}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Menu
        anchorEl={menu?.anchorEl}
        open={Boolean(menu)}
        onClose={() => setMenu(null)}
      >
        {menus?.map(({ action, icon, label = '' }, i) => (
          <MenuItem
            key={`${i}`}
            onClick={() => {
              if (Boolean(action)) {
                action();
              }
              setMenu(null);
            }}
          >
            {Boolean(icon) && (
              <ListItemIcon>
                <Icon>{icon}</Icon>
              </ListItemIcon>
            )}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default Apontamentos;
